import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

export default () => {
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.benchmarkrings.com/iframe/load/PgZU0QeqiPBdq37XsoSNbA"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <SEO title={'Benchmark'} />
      <div css={`padding-top:2rem;`}>
        <div id='benchmark-rings-embed'></div><script src='https://www.benchmarkrings.com/iframe/load/PgZU0QeqiPBdq37XsoSNbA'></script>
      </div>
    </Layout>
  )
}